import React, { useMemo } from 'react'
import * as PropTypes from 'prop-types'
import { navigate } from 'gatsby-plugin-intl'

import { withMemo, withWebsiteOpen } from '../../decorators'
import Layout from '../../components/Layout'
import VideoTemplate from '../../templates/Video'
import useConfiguration from '../../hooks/useConfiguration'
import { useIntl } from '../../intl/intl'
import routes from '../../router/routes'
import router from '../../router'
import * as appConfiguration from '../../configuration'


const Video = (props) => {
  const { pageContext, location } = props
  const { seoFallback } = useConfiguration()
  const t = useIntl()
  const videoTemplateProps = useMemo(() => ({
    ...t('youtube_video_id') !== 'none' ? {
      videoId: t('youtube_video_id'),
      opts: {
        width: '100%',
        height: '100%',
        host: 'http://www.youtube-nocookie.com',
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          playsinline: 1,
          showinfo: 0,
          controls: 0,
          rel: 0,
        },
      },
    } : {},
    ...t('youtube_video_id') === 'none' && t('videoplayback_path') ? {
      videoSourceUrl: t('videoplayback_path'),
    } : {},
    onClose: () => {
      navigate(router(routes.home.path))
    },
    layoutProps: {
      seo: {
        lang: pageContext?.language ?? null,
        title: seoFallback?.title ?? '',
        description: seoFallback?.description?.description ?? '',
        image: appConfiguration.app.url + seoFallback?.picture?.localFile?.childImageSharp?.fluid?.src ?? '',
        url: location?.href ?? '',
      },
    },
  }), [t, pageContext.language, seoFallback.title, seoFallback.description.description, seoFallback.picture.localFile.childImageSharp.fluid.src, location.href])

  return (
    <Layout {...videoTemplateProps.layoutProps}>
      <VideoTemplate {...videoTemplateProps} />
    </Layout>
  )
}

Video.propTypes = {
  // eslint-disable-next-line
  pageContext: PropTypes.object,
  // eslint-disable-next-line
  location: PropTypes.object,
}

Video.defaultProps = {
  pageContext: null,
  location: null,
}

export default withWebsiteOpen(withMemo()(Video))
