/* eslint-disable consistent-return */
/**
 * Calls the passed parameter only if type is function
 * @param fn
 * @param args
 */
export function safeCall(fn, ...args) {
  if (typeof fn === 'function') {
    return fn(...args)
  }
}
