import { createUseStyles } from 'react-jss'


export default createUseStyles({
  container: {
    position: 'relative',
    height: 'calc(var(--vh, 1vh) * 100)',
    width: '100%',
    overflow: 'hidden',
  },
  playerWrapper: {
    width: '100%',
    height: '100%',
  },
  player: {
    width: '100%',
    height: '100%',
  },
  nativePlayer: {
    width: '100%',
    height: '100%',
  },
  nativeVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center center',
  },
  close: {
    position: 'absolute',
    top: '1.5rem',
    right: '1.5rem',
  },
})
