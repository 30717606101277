/* eslint-disable jsx-a11y/media-has-caption */
import cx from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'
import YouTube from 'react-youtube'

import { safeCall } from '../../utils/safeCall'
import withMemo from '../../decorators/withMemo'
import Icon from '../../components/Icon'
import { iconsKeys } from '../../components/Icon/Icon.assets'

import useStyles from './styles'


const VideoTemplate = (props) => {
  const classes = useStyles(props)
  const { className, videoId, opts, onEnd, onReady, onPause, onPlay, onClose, videoSourceUrl } = props

  const $video = useRef(null)

  const handleEnd = useCallback((event) => {
    safeCall(onEnd)
  }, [onEnd])

  const handleReady = useCallback((event) => {
    if (opts?.playerVars?.autoplay === 1) {
      event.target.playVideo()
    }
    safeCall(onReady)
  }, [onReady, opts])

  const handlePause = useCallback((event) => {
    safeCall(onPause)
  }, [onPause])

  const handlePlay = useCallback((event) => {
    safeCall(onPlay)
  }, [onPlay])

  const handleClose = useCallback(() => {
    safeCall(onClose)
  }, [onClose])

  useEffect(() => {
    if ($video?.current) {
      $video.current.play()
    }
  }, [$video])

  return (
    <main className={cx(className, classes.container)}>
      {videoId && (
        <YouTube
          videoId={videoId}
          className={classes.player}
          containerClassName={classes.playerWrapper}
          opts={opts}
          onEnd={handleEnd}
          onReady={handleReady}
          onPause={handlePause}
          onPlay={handlePlay}
        />
      )}
      {videoSourceUrl && (
      <iframe
        src={videoSourceUrl}
        title="video-playback"
        height="100%"
        width="100%"
        frameBorder="0"
        allowFullScreen="true"
      />
      )}
      <Icon
        className={classes.close}
        icon={iconsKeys.Close}
        onClick={handleClose}
      />
    </main>
  )
}

export const VideoTemplatePropTypes = {
  className: PropTypes.string,
  videoId: PropTypes.string,
  videoSourceUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  opts: PropTypes.object,
  onEnd: PropTypes.func,
  onReady: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onClose: PropTypes.func,
}

VideoTemplate.propTypes = VideoTemplatePropTypes

VideoTemplate.defaultProps = {
  className: null,
  videoId: null,
  videoSourceUrl: null,
  opts: null,
  onEnd: null,
  onReady: null,
  onPause: null,
  onPlay: null,
  onClose: null,
}

export default withMemo()(VideoTemplate)
